import { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import { ApiKey, RevealedSecretKey } from "./types";

function ApiKeyReveal({ apiKey }: { apiKey: ApiKey }) {
  const [revealedSecretKey, setRevealedSecretKey] = useState<string | null>(
    null,
  );

  const revealSecretKey = (apiKey: ApiKey) => {
    fetch(`/api/api-keys/${apiKey.id}/reveal`, { method: "POST" })
      .then((res) => res.json())
      .then((k: RevealedSecretKey) => setRevealedSecretKey(k.secretKey))
      .catch((err) => console.log(err));
  };

  if (revealedSecretKey) return <>{revealedSecretKey}</>;
  else
    return (
      <>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => revealSecretKey(apiKey)}
          className="bi-eye-fill"
        ></span>{" "}
        {apiKey.redactedSecretKey}
      </>
    );
}

function ApiKeyRow({
  apiKey,
  showCreatedBy,
}: {
  apiKey: ApiKey;
  showCreatedBy: boolean;
}) {
  return (
    <tr>
      {showCreatedBy && <td>{apiKey.owner}</td>}
      <td>
        <ApiKeyReveal apiKey={apiKey} />
      </td>
      <td>{apiKey.sessionLength}</td>
      <td>{apiKey.notes}</td>
    </tr>
  );
}

function ApiKeysTable({
  apiKeys,
  showCreatedBy,
  showAdd,
  onAdd,
}: {
  apiKeys: Array<ApiKey>;
  showCreatedBy: boolean;
  showAdd: boolean;
  onAdd: (props: { notes: string }) => void;
}) {
  const [notes, setNotes] = useState<string>("");

  return (
    <Table>
      <thead>
        <tr>
          {showCreatedBy && <th>Created By</th>}
          <th>Key</th>
          <th>Session Length</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        {apiKeys.map((apiKey, _) => {
          return (
            <ApiKeyRow
              apiKey={apiKey}
              showCreatedBy={showCreatedBy}
              key={apiKey.id}
            />
          );
        })}

        {showAdd && (
          <tr>
            {showCreatedBy && <td></td>}
            <td></td>
            <td></td>
            <td>
              <Form.Control
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Notes"
              />
            </td>
            <td>
              <Button
                variant="light"
                onClick={() => {
                  setNotes("");
                  onAdd({ notes: notes });
                }}
              >
                ➕
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ApiKeysTable;
