import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { ReactComponent as LogoMark } from "./svg/logo-mark-yellow.svg";
import { CurrentUser } from "./types";

function Header({ currentUser }: { currentUser: CurrentUser | null }) {
  return (
    <header id="admin-header">
      <Container className="pt-2 mb-3">
        <Row>
          <Col sm={10}>
            <h1>
              <Link to="/">
                <LogoMark style={{ width: "100px" }} />
              </Link>
            </h1>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {currentUser ? (
              currentUser.id ? (
                <Link to={`/users/${currentUser.id}`}>
                  {currentUser.username}
                </Link>
              ) : (
                currentUser.username
              )
            ) : (
              "(nobody)"
            )}
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
