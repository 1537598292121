import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import ApiKeysTable from "./ApiKeysTable";
import { ApiKey, User } from "./types";

async function fetchUsers(): Promise<{ items: Array<User> }> {
  return fetch("/api/users").then((res) => res.json());
}

function Users() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<User> | null>(null);

  const createApiKey = (user: User, props: { notes: string }) => {
    fetch("/api/api-keys", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ owner: user.id, notes: props.notes }),
    })
      .then((res) => res.json())
      .then((k: ApiKey) => {
        setUsers(
          users!.map((u) => {
            if (u.id === user.id) {
              return {
                ...u,
                apiKeys: [...u.apiKeys, k],
              };
            } else {
              return u;
            }
          }),
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);

    fetchUsers()
      .then((users) => setUsers(users.items))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (!users) {
    return <Alert variant="warning">Failed to load.</Alert>;
  } else {
    return (
      <>
        <h1 className="mt-4">Users</h1>

        {users.map((user) => {
          const notARealUser = user.id === "_static";

          return (
            <div key={user.id}>
              <h2>
                {notARealUser ? (
                  "Static API Keys"
                ) : (
                  <Link to={`/users/${user.id}`}>{user.email}</Link>
                )}
              </h2>

              <ApiKeysTable
                apiKeys={user.apiKeys}
                showCreatedBy={false}
                showAdd={!notARealUser}
                onAdd={(props) => createApiKey(user, props)}
              />
            </div>
          );
        })}
      </>
    );
  }
}

export default Users;
