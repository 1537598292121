import { useEffect, useState } from "react";

import { Alert, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { getAPI } from "../api";
import { formatDateTime } from "../utils";

type APIRequestSummary = {
  id: string;
  created: number;
  organization: string;

  req_path: string;
  res_status_code: number;
};

async function fetchAPIRequests(): Promise<{
  items: Array<APIRequestSummary>;
}> {
  return getAPI("/api/api-requests");
}

function APIRequestsTable({
  apiRequests,
}: {
  apiRequests: Array<APIRequestSummary>;
}) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Path</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {apiRequests.map((apiRequest, i) => {
          return (
            <tr key={i}>
              <td>
                <Link
                  to={`/api-requests/${apiRequest.organization}/${apiRequest.id}`}
                >
                  {formatDateTime(apiRequest.created)}
                </Link>
              </td>
              <td>{apiRequest.req_path}</td>
              <td>{apiRequest.res_status_code}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function ListAPIRequests() {
  const [loading, setLoading] = useState(false);
  const [apiRequests, setAPIRequests] =
    useState<Array<APIRequestSummary> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchAPIRequests()
      .then((apiRequests) => setAPIRequests(apiRequests.items))
      .catch((err: Error) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else if (!apiRequests) {
    return (
      <Alert variant="warning">
        Could not load API requests: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h1 className="mt-4">API Requests</h1>

        <APIRequestsTable apiRequests={apiRequests} />
      </>
    );
  }
}

export default ListAPIRequests;
