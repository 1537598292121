import type { Chalk, ChooseFnResult, LLMConfig } from "./types";

export async function submitToBagel(
  chalk: Chalk,
  llmConfig: LLMConfig,
): Promise<ChooseFnResult> {
  return fetch("/api/llm/choose-fn", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ chalk: chalk, llm_config: llmConfig }),
  }).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}
