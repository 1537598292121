import { useEffect, useState } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import type { CurrentUser, NeedsLogin } from "./types";
import Auth from "./Auth";
import Header from "./Header";

const Layout = () => {
  const [whoami, setWhoami] = useState<CurrentUser | null>(null);
  const [needsLogin, setNeedsLogin] = useState<NeedsLogin | null>(null);

  useEffect(() => {
    fetch("/api/auth/whoami")
      .then((res) => {
        if (res.status === 200)
          res.json().then((whoami: CurrentUser) => setWhoami(whoami));
        else if (res.status === 401)
          res
            .json()
            .then((needsLogin: NeedsLogin) => setNeedsLogin(needsLogin));
      })
      .catch((err) => console.log(err));
  }, []);

  if (!whoami) {
    if (!needsLogin) {
      return (
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      );
    } else if (!whoami) {
      return (
        <GoogleOAuthProvider clientId={needsLogin.client_id}>
          <Header currentUser={whoami} />
          <Container>
            <Auth onSuccess={(whoami) => setWhoami(whoami)} />
          </Container>
        </GoogleOAuthProvider>
      );
    }
  } else {
    return (
      <>
        <Container>
          <Header currentUser={whoami} />
          <Container>
            <Outlet />
          </Container>
        </Container>
      </>
    );
  }
};

export default Layout;
