import { useState } from "react";

import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import PaginatedChalkLogs from "./PaginatedChalkLogs";

function ChalkLogs() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [sessionKey, _setSessionKey] = useState<string | null>(
    searchParams.get("sessionKey"),
  );
  const [owner, _setOwner] = useState<string | null>(searchParams.get("owner"));

  const [debouncedSessionKey] = useDebounce(sessionKey, 500);
  const [debouncedOwner] = useDebounce(owner, 500);

  const setSessionKey = (sessionKey: string) => {
    setSearchParams((qs) => {
      qs.set("sessionKey", sessionKey);
      return qs;
    });
    _setSessionKey(sessionKey);
  };

  const setOwner = (owner: string) => {
    setSearchParams((qs) => {
      qs.set("owner", owner);
      return qs;
    });
    _setOwner(owner);
  };

  const chalkLogSearchParams = {
    sessionKey: debouncedSessionKey,
    owner: debouncedOwner,
  };

  return (
    <>
      <h3>Filters</h3>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Owner
        </Form.Label>
        <Col>
          <Form.Control
            type="search"
            value={owner || ""}
            onChange={(e) => setOwner(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Session
        </Form.Label>
        <Col>
          <Form.Control
            type="search"
            value={sessionKey || ""}
            onChange={(e) => setSessionKey(e.target.value)}
          />
        </Col>
      </Form.Group>

      <div className="mt-4">
        <PaginatedChalkLogs
          title="Results"
          search={chalkLogSearchParams}
          // setting a key here ensures that the pagination state gets reset when
          // the search params get reset.
          // I expect there's a better way to do this.
          key={JSON.stringify(chalkLogSearchParams)}
          onSearchSession={setSessionKey}
          onSearchOwner={setOwner}
          showAgent={false}
        />
      </div>
    </>
  );
}

export default ChalkLogs;
