import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import type { OrganizationSummary } from "../api/types";
import { fetchOrganizations } from "../api";

function NewOrganizationForm({
  onSubmit,
}: {
  onSubmit: (o: OrganizationSummary) => void;
}) {
  const [shortName, setShortName] = useState<string>("");
  const [name, setName] = useState<string>("");

  return (
    <Row>
      <Col>
        <Form.Label>Short Name</Form.Label>
        <Form.Control
          value={shortName}
          onChange={(e) => setShortName(e.target.value)}
        />
      </Col>

      <Col>
        <Form.Label>Name</Form.Label>
        <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
      </Col>

      <Col>
        <Form.Control
          type="submit"
          className="btn btn-primary mt-2"
          onClick={() => onSubmit({ short_name: shortName, name: name })}
        />
      </Col>
    </Row>
  );
}

function ListOrganizations() {
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] =
    useState<Array<OrganizationSummary> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const createOrganization = (o: OrganizationSummary) => {
    fetch("/api/organizations", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(o),
    })
      .then((res) => res.json())
      .then((o: OrganizationSummary) =>
        setOrganizations([o, ...organizations!]),
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);

    fetchOrganizations()
      .then((organizations) => setOrganizations(organizations.items))
      .catch((err: Error) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else if (!organizations) {
    return (
      <Alert variant="warning">
        Could not load organizations: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h1>Create Organization</h1>

        <NewOrganizationForm onSubmit={createOrganization} />

        <h1 className="mt-4">Organizations</h1>

        <ul>
          {organizations.map((o) => {
            return (
              <li>
                <Link to={`/organizations/${o.short_name}`}>{o.name}</Link> (
                {o.short_name})
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

export default ListOrganizations;
