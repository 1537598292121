import { useEffect, useState } from "react";

import { Button, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import type { Organization, Project, User } from "../api/types";
import { fetchOrganization, postAPI } from "../api";
import ApiKeysTable from "../Users/ApiKeysTable";

type NewUser = {
  email: string;
};

type NewProject = {
  name: string;
};

function NewUserRow({ onSubmit }: { onSubmit: (u: NewUser) => void }) {
  const [email, setEmail] = useState<string>("");

  return (
    <tr>
      <td>
        <Form.Control
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
        />
      </td>

      <td>
        <Button variant="light" onClick={() => onSubmit({ email })}>
          ➕
        </Button>
      </td>
    </tr>
  );
}

function NewProjectRow({ onSubmit }: { onSubmit: (p: NewProject) => void }) {
  const [name, setName] = useState<string>("");

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name"
        />
      </td>

      <td>
        <Button variant="light" onClick={() => onSubmit({ name })}>
          ➕
        </Button>
      </td>
    </tr>
  );
}

function OrganizationPage() {
  const { shortName } = useParams();

  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const createUser = (newUser: NewUser) => {
    postAPI(`/api/organizations/${shortName}/users`, newUser)
      .then((u: User) =>
        setOrganization({
          ...organization!,
          users: [...organization!.users, u],
        }),
      )
      .catch((err) => console.log(err));
  };

  const createProject = (newProject: NewProject) => {
    postAPI(`/api/organizations/${shortName}/projects`, newProject)
      .then((p: Project) =>
        setOrganization({
          ...organization!,
          projects: [...organization!.projects, p],
        }),
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);

    fetchOrganization(shortName!)
      .then((organization) => setOrganization(organization))
      .catch((err: Error) => setError(err))
      .finally(() => setLoading(false));
  }, [shortName]);

  if (loading) {
    return <div>Loading...</div>;
  } else if (!organization) {
    return (
      <Alert variant="warning">
        Could not load organizations: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h1>{organization.name}</h1>

        <h2>Users</h2>

        <Table>
          <thead>
            <tr>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {organization.users.map((u) => {
              return (
                <tr key={u.id}>
                  <td>{u.email}</td>
                </tr>
              );
            })}
            <NewUserRow onSubmit={(u) => createUser(u)} />
          </tbody>
        </Table>

        <h2>Projects</h2>

        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {organization.projects.map((p) => {
              return (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>{p.name}</td>
                </tr>
              );
            })}
            <NewProjectRow onSubmit={(p) => createProject(p)} />
          </tbody>
        </Table>

        <h2>API Keys</h2>

        <ApiKeysTable
          apiKeys={organization.api_keys}
          showCreatedBy={true}
          showAdd={false}
          onAdd={(newApiKey) => null}
        />
      </>
    );
  }
}

export default OrganizationPage;
