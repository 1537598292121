import { Alert } from "react-bootstrap";

import type { FnDesc, FnParameter } from "../bagel-api/types";

function ShowArrayParam({ items }: { items: any }) {
  return (
    <>
      <br />
      array of:
      <ul>
        <li>
          <ShowFunctionParam paramDesc={items} />
        </li>
      </ul>
    </>
  );
}

function ShowObjectParam({
  properties,
}: {
  properties: Record<string, FnParameter> | undefined | null;
}) {
  if (!properties || Object.keys(properties).length === 0) {
    return (
      <>
        <br />
        No properties defined.
      </>
    );
  }

  return (
    <>
      <br />
      properties:
      <ul>
        {Object.keys(properties).map((propName) => {
          return (
            <li key={propName}>
              <code>{propName}</code>:{" "}
              <ShowFunctionParam paramDesc={properties[propName]} />
            </li>
          );
        })}
      </ul>
    </>
  );
}

function ShowFunctionParam({ paramDesc }: { paramDesc: FnParameter }) {
  return (
    <>
      <code>{paramDesc.value_type}</code>
      {paramDesc.docs && <> - {paramDesc.docs}</>}
      {paramDesc.value_type === "array" && (
        <ShowArrayParam items={paramDesc.items} />
      )}
      {paramDesc.value_type === "object" && (
        <ShowObjectParam properties={paramDesc.properties} />
      )}
    </>
  );
}

function ShowFunctions({ functions }: { functions: Array<FnDesc> }) {
  if (functions.length === 0) {
    return <Alert>No functions defined.</Alert>;
  }

  return (
    <ul>
      {functions.map((fn) => {
        return (
          <li key={fn.name}>
            <code>{fn.name}</code>
            <br />
            {fn.docs}
            <br />
            {Object.keys(fn.parameters).length === 0 ? (
              "No arguments."
            ) : (
              <>
                args:
                <ul>
                  {Object.keys(fn.parameters).map((paramName) => {
                    return (
                      <li key={paramName}>
                        <code>{paramName}</code>:{" "}
                        <ShowFunctionParam
                          paramDesc={fn.parameters[paramName]}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default ShowFunctions;
