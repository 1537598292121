import { ReactNode, useState } from "react";

import { Button, ButtonGroup, Form, Table } from "react-bootstrap";

import type { Fn, HistoryItem, Source } from "../bagel-api/types";
import Behavior from "../view-chalk/Behavior";
import FunctionCall from "../view-chalk/FunctionCall";

import "./style.css";

function FunctionCallEditor({
  onChange,
}: {
  onChange: (f: Fn | null) => void;
}) {
  const [input, setInput] = useState<string>("");
  const [functionCall, setFunctionCall] = useState<Fn | null>(null);

  const updateInput = (str: string) => {
    setInput(str);

    const fnRegex = /^(?<name>[^(]+)\((?<args>.*)\)$/;
    const fnMatch = fnRegex.exec(str)?.groups;

    if (fnMatch) {
      const argStr = fnMatch["args"];
      const parsedArgs: Record<string, any> = {};

      // "y" is the "sticky" flag, which allows us to iteratively apply the regex
      const argsRegex = /(,\s*)?(?<paramName>[^=]+)=(?<paramValue>[^,]+)/y;

      let argMatch;
      while ((argMatch = argsRegex.exec(argStr)?.groups)) {
        parsedArgs[argMatch["paramName"]] = argMatch["paramValue"];
      }

      const newFn: Fn = {
        name: fnMatch["name"],
        args: parsedArgs,
      };

      setFunctionCall(newFn);
      onChange(newFn);
    } else {
      setFunctionCall(null);
      onChange(null);
    }
  };

  return (
    <>
      <Form.Control
        value={input}
        onChange={(e) => updateInput(e.target.value)}
      />
      {functionCall && <FunctionCall selectedFn={functionCall} />}
    </>
  );
}

function Editable({
  children,
  str,
  onChange,
}: {
  children: ReactNode;
  str: string;
  onChange: (str: string) => void;
}) {
  const [showEditor, setShowEditor] = useState(false);
  const [newStr, setNewStr] = useState(str);
  const [editorRows, setEditorRows] = useState<number | null>(null);

  const handleFinishEdit = () => {
    setShowEditor(false);
    onChange(newStr);
  };

  const handleCancelEdit = () => {
    setNewStr(str);
    setShowEditor(false);
  };

  const handleEdit = () => {
    setEditorRows(str.split("\n").length);
    setShowEditor(true);
  };

  if (showEditor) {
    return (
      <>
        <Form.Control
          value={newStr}
          onChange={(e) => setNewStr(e.target.value)}
          as="textarea"
          rows={editorRows!}
        />
        <ButtonGroup>
          <Button
            variant="success"
            className="bi-check"
            onClick={handleFinishEdit}
          ></Button>
          <Button
            variant="secondary"
            className="bi-x"
            onClick={handleCancelEdit}
          ></Button>
        </ButtonGroup>
      </>
    );
  }

  return (
    <div onClick={handleEdit} className="history-item-editor">
      {children}
    </div>
  );
}

function EditableHistoryItem({
  item,
  onChangeContent,
}: {
  item: HistoryItem;
  onChangeContent: (newContent: string) => void;
}) {
  if (item.function_call) {
    return <FunctionCall selectedFn={item.function_call} />;
  }

  if (item.action === "image") {
    return <img src={item.content} alt="" />;
  }

  return (
    <Editable str={item.content} onChange={onChangeContent}>
      <Behavior content={item.content} />
    </Editable>
  );
}

function HistoryItemEditor({
  historyItems,
  onChange,
}: {
  historyItems: Array<HistoryItem>;
  onChange: (historyItems: Array<HistoryItem>) => void;
}) {
  const [newItemSource, setNewItemSource] = useState<Source>("user");
  const [newItemContent, setNewItemContent] = useState<string>("");
  const [newItemFunctionCall, setNewItemFunctionCall] = useState<Fn | null>(
    null,
  );

  const possibleSources: Array<Source> = [
    // bagel defines these sources, but never uses them.
    //    "system",
    //    "function",
    "user",
    "assistant",
  ];

  const handleNewItemSourceChange = (source: string) => {
    // @ts-ignore
    setNewItemSource(source);
  };

  const handleAddItem = () => {
    const newHistoryItem: HistoryItem = {
      time_code: -1,
      source: newItemSource,
      content: newItemSource === "assistant" ? "" : newItemContent,
      function_call: newItemSource === "assistant" ? newItemFunctionCall : null,
    };
    const newHistoryItems = [...historyItems, newHistoryItem];

    setNewItemSource("user");
    setNewItemContent("");
    setNewItemFunctionCall(null);

    onChange(newHistoryItems);
  };

  const setItemContent = (i: number, content: string) => {
    const updatedItem = { ...historyItems[i], content: content };
    const newHistoryItems = [
      ...historyItems.slice(0, i),
      updatedItem,
      ...historyItems.slice(i + 1),
    ];
    onChange(newHistoryItems);
  };

  const deleteItem = (i: number) => {
    const newHistoryItems = historyItems
      .slice(0, i)
      .concat(historyItems.slice(i + 1));
    onChange(newHistoryItems);
  };

  return (
    <Table>
      <tbody>
        {historyItems.map((item: HistoryItem, i) => {
          return (
            <tr key={i}>
              <td className="item-source">
                <span>{item.source}</span>
              </td>
              <td>
                <EditableHistoryItem
                  item={item}
                  onChangeContent={(newContent) =>
                    setItemContent(i, newContent)
                  }
                />
              </td>
              <td>
                <i
                  className="bi-x"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteItem(i)}
                />
              </td>
            </tr>
          );
        })}

        <tr>
          <td>
            <Form.Select
              value={newItemSource}
              onChange={(e) => handleNewItemSourceChange(e.target.value)}
            >
              {possibleSources.map((source) => {
                return <option key={source}>{source}</option>;
              })}
            </Form.Select>
          </td>
          <td>
            {newItemSource === "assistant" ? (
              <FunctionCallEditor onChange={(f) => setNewItemFunctionCall(f)} />
            ) : (
              <Form.Control
                value={newItemContent}
                onChange={(e) => setNewItemContent(e.target.value)}
              />
            )}
          </td>
          <td>
            <Button className="bi-plus" onClick={handleAddItem} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default HistoryItemEditor;
