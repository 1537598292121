import { useEffect, useState } from "react";

type Model = {
  endpointName: string;
  endpointVersion: string;
  description: string | null;
  maxTokens: number;
};

function EndpointDescription({
  name,
  models,
}: {
  name: string;
  models: Array<Model>;
}) {
  return (
    <>
      <h2>
        Endpoint: <code>{name}</code>
      </h2>

      <ul>
        {models.map((model) => {
          return (
            <li key={`${name}-${model.endpointVersion}`}>
              <code>{model.endpointVersion}</code>
              {model.description && (
                <>
                  <br />
                  {model.description}
                </>
              )}
              <br />
              Max tokens: {model.maxTokens}
            </li>
          );
        })}
      </ul>
    </>
  );
}

function ModelsPage() {
  const url = "/api/models";

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, Array<Model>> | null>(null);

  useEffect(() => {
    setLoading(true);
    setData(null);

    fetch(url)
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
  } else if (!data) {
  } else {
    return (
      <>
        <h1>Supported Models</h1>

        {Object.keys(data).map((endpointName) => {
          return (
            <EndpointDescription
              key={endpointName}
              name={endpointName}
              models={data[endpointName]}
            />
          );
        })}
      </>
    );
  }
}

export default ModelsPage;
