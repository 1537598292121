function DumpMetadata({ metadata }: { metadata: Record<string, string> }) {
  return (
    <ul style={{ listStyleType: "none", paddingLeft: 0 }} className="mb-0">
      {Object.keys(metadata).map((key) => {
        return (
          <li key={key}>
            <i>{key}</i>: {metadata[key]}
          </li>
        );
      })}
    </ul>
  );
}

export default DumpMetadata;
