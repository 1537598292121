import { useState } from "react";

import { Form, Table } from "react-bootstrap";

import type { Slate } from "../bagel-api/types";

import "./style.css";

function Editable({
  str,
  onChange,
}: {
  str: string;
  onChange: (str: string) => void;
}) {
  const [showEditor, setShowEditor] = useState(false);
  const [newStr, setNewStr] = useState(str);
  const [editorRows, setEditorRows] = useState<number | null>(null);

  const handleFinishEdit = () => {
    setShowEditor(false);
    onChange(newStr);
  };

  const handleEdit = () => {
    setEditorRows(str.split("\n").length);
    setShowEditor(true);
  };

  if (showEditor) {
    return (
      <Form.Control
        value={newStr}
        onChange={(e) => setNewStr(e.target.value)}
        onBlur={(_) => {
          handleFinishEdit();
          return true;
        }}
        as="textarea"
        rows={editorRows!}
        className="slate-editor"
        style={{ backgroundColor: "#ffe" }}
        autoFocus
      />
    );
  }

  return (
    <div className="slate-component" onClick={handleEdit}>
      {str}
    </div>
  );
}

function SlateComponent({
  slate,
  onChange,
}: {
  slate: Slate;
  onChange: (slate: Slate) => void;
}) {
  return (
    <Table>
      <tbody>
        <tr>
          <th>BIOS</th>
          <td className="col-10">
            <Editable
              str={slate.bios}
              onChange={(x) => onChange({ ...slate, bios: x })}
            />
          </td>
        </tr>
        <tr>
          <th>Characterization</th>
          <td>
            <Editable
              str={slate.characterization}
              onChange={(x) => onChange({ ...slate, characterization: x })}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default SlateComponent;
