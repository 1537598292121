import { createBrowserRouter } from "react-router-dom";

import AdminIndex from "./AdminIndex";
import ListAPIRequests from "./api-requests/ListAPIRequests";
import ShowAPIRequest from "./api-requests/ShowAPIRequest";
import ChalkPlayground from "./chalk-playground/";
import ChalkLog from "./ChalkLog";
import ChalkLogs from "./ChalkLogs";
import ErrorPage from "./ErrorPage";
import Layout from "./Layout";
import ModelsPage from "./Models";
import ListOrganizations from "./organizations/ListOrganizations";
import OrganizationPage from "./organizations/OrganizationPage";
import SessionPage from "./Session/";
import UserPage from "./User/UserPage";
import Users from "./Users/";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <AdminIndex />,
      },
      {
        path: "/organizations",
        element: <ListOrganizations />,
      },
      {
        path: "/organizations/:shortName",
        element: <OrganizationPage />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/chalk-logs",
        element: <ChalkLogs />,
      },
      {
        path: "/chalk-logs/:sessionId/:momentId",
        element: <ChalkLog />,
      },
      {
        path: "/users/:userId",
        element: <UserPage />,
      },
      {
        path: "/sessions/:sessionId",
        element: <SessionPage />,
      },
      {
        path: "/sessions/:sessionId/agent/:agentId",
        element: <SessionPage />,
      },
      {
        path: "/models",
        element: <ModelsPage />,
      },
      {
        path: "/chalk-playground/:sessionId/:chalkLogId",
        element: <ChalkPlayground />,
      },
      {
        path: "/api-requests",
        element: <ListAPIRequests />,
      },
      {
        path: "/api-requests/:organization/:requestId",
        element: <ShowAPIRequest />,
      },
    ],
  },
]);

export default router;
