import type { Fn } from "../bagel-api/types";

function FunctionCall({ selectedFn }: { selectedFn: Fn }) {
  return (
    <code style={{ whiteSpace: "pre-wrap" }}>
      {selectedFn.name}(
      {Object.keys(selectedFn.args)
        .map((argName, _i) => {
          const prettyValue = JSON.stringify(selectedFn.args[argName], null, 2);
          return `${argName}=${prettyValue}`;
        })
        .join(", ")}
      )
    </code>
  );
}

export default FunctionCall;
