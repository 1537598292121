import { useEffect, useState } from "react";

import { Alert, Badge, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { formatDateTime } from "../utils";
import DumpMetadata from "./DumpMetadata";
import { Session } from "./types";

async function fetchSession(id: string): Promise<Session> {
  return fetch(`/api/sessions/${id}`).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

function SessionDetails({
  sessionId,
  selectedAgent,
  autoUpdate = false,
  onSessionState = null,
}: {
  sessionId: string;
  selectedAgent: string | null;
  autoUpdate?: boolean;
  onSessionState?: ((isValid: boolean) => void) | null;
}) {
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<Session | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    const doFetch = async () => {
      fetchSession(sessionId)
        .then((session) => {
          setSession(session);

          // refresh every 15s as long as the session is valid.
          if (autoUpdate && session.valid) {
            setTimeout(doFetch, 15 * 1000);
          }
        })
        .catch((err: Error) => {
          setError(err);

          // try again in 15s
          if (autoUpdate) {
            setTimeout(doFetch, 15 * 1000);
          }
        })
        .finally(() => setLoading(false));
    };

    doFetch();
  }, [sessionId, autoUpdate]);

  useEffect(() => {
    if (onSessionState && session?.valid !== undefined)
      onSessionState(session?.valid);
  }, [onSessionState, session?.valid]);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (!session) {
    return (
      <Alert variant="warning">Could not load session: {error?.message}.</Alert>
    );
  } else {
    return (
      <>
        <table width="100%" className="mb-4">
          <tbody>
            <tr>
              <th>Owner</th>
              <td>
                <Link to={`/users/${session.ownerId}`}>{session.ownerId}</Link>
              </td>
            </tr>

            <tr>
              <th>Created At</th>
              <td>{formatDateTime(session.createdAt)}</td>
            </tr>

            <tr>
              <th>Status</th>
              {session.valid ? (
                <td>
                  <Badge bg="success">Active</Badge> Expires at{" "}
                  {formatDateTime(session.expiresAt)}
                </td>
              ) : (
                <td>
                  <Badge bg="danger">expired</Badge>
                </td>
              )}
            </tr>
            <tr>
              <th>Metadata</th>
              <td>
                <DumpMetadata metadata={session.metadata} />
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Agents</h3>

        <Container>
          <Row sm={2} md={4} lg={6} className="g-3">
            {Object.keys(session.agentMetadata).map((agentId) => {
              const maybeActive = agentId === selectedAgent ? "active" : "";
              const metadata = session.agentMetadata[agentId];
              const agentName = metadata["name"];

              return (
                <Col className="d-grid">
                  <Link
                    className={`btn btn-outline-secondary ${maybeActive}`}
                    to={`/sessions/${session.id}/agent/${agentId}`}
                  >
                    {agentName || agentId}
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}

export default SessionDetails;
