import { useEffect, useState } from "react";

import { Alert, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import type { Fn } from "../bagel-api/types";
import type { Chalk } from "./types";
import { fetchChalkLog } from "../api/";
import ShowFunctions from "../view-chalk/ShowFunctions";
import SlateEditor from "../view-chalk/Slate";
import HistoryItemEditor from "./HistoryItemEditor";
import ResultPanel from "./ResultPanel";

function ChalkEditor({
  chalk,
  onChange,
}: {
  chalk: Chalk;
  onChange: (newChalk: Chalk) => void;
}) {
  return (
    <>
      <h3>Slate</h3>

      <SlateEditor
        slate={chalk.slate}
        onChange={(slate) => onChange({ ...chalk, slate: slate })}
      />

      <h3>History</h3>

      <HistoryItemEditor
        historyItems={chalk.history}
        onChange={(historyItems) =>
          onChange({ ...chalk, history: historyItems })
        }
      />
    </>
  );
}

function ChalkPlayground() {
  const { sessionId, chalkLogId } = useParams();

  const [loading, setLoading] = useState(false);
  const [chalk, setChalk] = useState<Chalk | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    setChalk(null);

    fetchChalkLog(sessionId!, chalkLogId!)
      .then((data) => {
        if ("functions" in data.raw_log.log) {
          setChalk({
            slate: data.raw_log.log.slate,
            history: data.raw_log.log.chalk,
            functions: data.raw_log.log.functions,
          });
        } else {
          throw new Error("Not a FnLog");
        }
      })
      .catch((err: Error) => setError(err))
      .finally(() => setLoading(false));
  }, [sessionId, chalkLogId]);

  const addResultToHistory = (fn: Fn) => {
    setChalk({
      ...chalk!,
      history: [
        ...chalk!.history,
        { time_code: -1, source: "assistant", content: "", function_call: fn },
      ],
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (!chalk) {
    return (
      <Alert variant="warning">
        Could not load chalk log: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        {/* padding is the same as the ResultPanel height below */}
        <Col style={{ paddingBottom: "10em" }}>
          <ChalkEditor
            chalk={chalk}
            onChange={(newChalk) => setChalk(newChalk)}
          />

          <h3>Functions</h3>

          <ShowFunctions functions={chalk.functions} />
        </Col>

        <div
          className="fixed-bottom"
          style={{
            background: "white",
            borderTop: "1px solid black",
            paddingTop: "1em",
            /* height is the same as the ChalkEditor padding above */
            height: "10em",
          }}
        >
          <Col className="container">
            <ResultPanel
              chalk={chalk}
              onAddResultToHistory={addResultToHistory}
            />
          </Col>
        </div>
      </>
    );
  }
}

export default ChalkPlayground;
