import type { FullChalkLog, Organization, OrganizationSummary } from "./types";

export async function getAPI(url: string): Promise<any> {
  return fetch(url).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function postAPI(url: string, body: any): Promise<any> {
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function fetchChalkLog(
  sessionId: string,
  chalkLogId: string,
): Promise<FullChalkLog> {
  const url = `/api/chalk-logs/${sessionId}/${chalkLogId}`;
  return getAPI(url);
}

export async function fetchOrganizations(): Promise<{
  items: Array<OrganizationSummary>;
}> {
  return getAPI("/api/organizations");
}

export async function fetchOrganization(
  shortName: string,
): Promise<Organization> {
  const url = `/api/organizations/${shortName}`;
  return getAPI(url);
}
