import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

import { LLMApiKey, User } from "../Users/types";
import Sessions from "./Sessions";

type NewLLMApiKey = {
  endpointName: string;
  secret: string;
};

async function fetchUser(userId: string): Promise<User> {
  return fetch(`/api/users/${userId}`).then((res) => {
    if (res.status === 200) return res.json();
  });
}

function LLMApiKeys({
  keys,
  onAddKey,
}: {
  keys: Array<LLMApiKey>;
  onAddKey: (newKey: NewLLMApiKey) => void;
}) {
  const [endpointName, setEndpointName] = useState<string>("openai");
  const [secret, setSecret] = useState<string>("");

  return (
    <>
      <h2>LLM API Keys</h2>
      <Table>
        <thead>
          <tr>
            <th>Endpoint</th>
            <th>Secret</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((k) => {
            return (
              <tr key={k.endpointName}>
                <td>{k.endpointName}</td>
                <td>{k.redactedSecret}</td>
              </tr>
            );
          })}

          <tr>
            <td>
              <Form.Select
                value={endpointName}
                onChange={(e) => setEndpointName(e.target.value)}
              >
                <option>openai</option>
              </Form.Select>
            </td>

            <td>
              <Form.Control
                type="search"
                value={secret || ""}
                onChange={(e) => setSecret(e.target.value)}
              />
            </td>

            <td>
              <Button
                variant="light"
                onClick={() => onAddKey({ endpointName, secret })}
              >
                ➕
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

function UserDetails({ userId }: { userId: string }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchUser(userId)
      .then((user) => setUser(user))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [userId]);

  const createLLMApiKey = (key: NewLLMApiKey) => {
    fetch(`/api/llm-api-keys`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...key, owner: userId }),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((newKey: LLMApiKey) =>
        setUser({
          ...user!,
          llmApiKeys: [...user!.llmApiKeys, newKey],
        }),
      )
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <>
        <h1>{userId}</h1>
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      </>
    );
  } else if (!user) {
    return (
      <>
        <h1>{userId}</h1>
        <p>
          Could not find this user in the database. Possibly this is a
          deprecated static Bagel API key?
        </p>
      </>
    );
  } else {
    return (
      <>
        <h1>{user.email ? user.email : userId}</h1>

        <LLMApiKeys keys={user.llmApiKeys} onAddKey={createLLMApiKey} />
      </>
    );
  }
}

function UserPage() {
  const { userId } = useParams();

  return (
    <>
      <UserDetails userId={userId!} />

      <h2>Sessions</h2>

      <Sessions userId={userId!} />
    </>
  );
}

export default UserPage;
